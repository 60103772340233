import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut, getIdToken } from 'firebase/auth';

// ------------------------------------------------------------------------
// DEV
// ------------------------------------------------------------------------
// const firebaseConfig = {
//   apiKey: 'AIzaSyAjZWA-ep6SlptVsywqETf1igBLes3Iv6Y',
//   authDomain: 'pkg-digi-grp-dev-fintech-cfmgr.firebaseapp.com',
//   projectId: 'pkg-digi-grp-dev-fintech-cfmgr',
//   storageBucket: 'pkg-digi-grp-dev-fintech-cfmgr.appspot.com',
//   messagingSenderId: '270842818909',
//   appId: '1:270842818909:web:699023c9896c6b073df8b4',
// };

// ------------------------------------------------------------------------
// TEST
// ------------------------------------------------------------------------
const firebaseConfig = {
  apiKey: "AIzaSyCzmaR4F6xWq8NeHe8yQZd4DxLCQuyuaFk",
  authDomain: "pkg-digi-grp-tst-fintech-cfmgr.firebaseapp.com",
  projectId: "pkg-digi-grp-tst-fintech-cfmgr",
  storageBucket: "pkg-digi-grp-tst-fintech-cfmgr.appspot.com",
  messagingSenderId: "305665445867",
  appId: "1:305665445867:web:5f2963d4864fe71824d3ae"
};

// ------------------------------------------------------------------------
// PROD
// ------------------------------------------------------------------------
// const firebaseConfig = {
//   apiKey: "AIzaSyB4Ye3HXUN-wLi7JUUE9QJCsfaSqjRuZxk",
//   authDomain: "pkg-digi-grp-prd-fintech-cfmgr.firebaseapp.com",
//   projectId: "pkg-digi-grp-prd-fintech-cfmgr",
//   storageBucket: "pkg-digi-grp-prd-fintech-cfmgr.appspot.com",
//   messagingSenderId: "10001765587",
//   appId: "1:10001765587:web:c2847cd6d72252fa657026"
// };


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// -----------------------------------------------------------------------
// DEV
// -----------------------------------------------------------------------
// auth.tenantId = 'admin-console-7n9er';

// -----------------------------------------------------------------------
// TEST
// -----------------------------------------------------------------------
auth.tenantId = 'admin-console-dvmlh';

// -----------------------------------------------------------------------
// PROD
// -----------------------------------------------------------------------
// auth.tenantId = 'admin-console-n3yyv';



const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export { logInWithEmailAndPassword, logout, auth, getIdToken };
